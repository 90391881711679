import React from "react";
import { Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapPin,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import Carousel from "./Carousel";
import CardList from "./common/CardList";
import ImageGrid from "./common/ImageGrid";
import Leadership from "./common/Leadership";

import "./Layout.css";
import "./Home.css";

const carouselItems = [
  {
    src: "https://cannon-studio.s3.amazonaws.com/globalconnexx/gallery/IMG_0525.jpg",
    altText: "",
  },
  {
    src: "https://cannon-studio.s3.amazonaws.com/globalconnexx/philippines/sharing-the-gospel.jpg",
    altText: "",
  },
  {
    src: "https://cannon-studio.s3.amazonaws.com/globalconnexx/philippines/bible-study.jpg",
    altText: "",
  },
];

export default () => {
  return (
    <Container className="px-0" fluid={true}>
      <header>
        <Row>
          <Col sm={12} md={{ size: 10, offset: 1 }}>
            <Row>
              <Col sm={12} md={4} className="logo-col">
                <a href="/">
                  <img
                    src="https://cannon-studio.s3.amazonaws.com/globalconnexx/GC_5X2BANNER_WHITE.png"
                    alt="Global Connexx"
                    className="img-fluid"
                    style={{ maxHeight: "65px" }}
                  />
                </a>
              </Col>
              <Col sm={12} md={8} className="nav-col text-right">
                <ul>
                  <li>
                    <a href="#about">About</a>
                  </li>
                  <li>
                    <a href="#donations">Donate</a>
                  </li>
                  <li>
                    <a href="#causes">Causes</a>
                  </li>
                  <li>
                    <a href="#gallery">Gallery</a>
                  </li>
                  <li>
                    <a href="#leadership">Leadership</a>
                  </li>
                  <li>
                    <a href="#contact">Contact</a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </header>
      <div className="carousel-wrapper">
        <Carousel slides={carouselItems} />
      </div>
      <Row className="mx-0">
        <Col
          sm={12}
          md={{ size: 10, offset: 1 }}
          style={{ paddingTop: "48px" }}
        >
          <Row>
            <Col sm={12} md={4} className="text-center">
              <img
                className="img-fluid"
                src="https://cannon-studio.s3.amazonaws.com/globalconnexx/about/globalconnexxmap.png"
                alt=""
                style={{ maxHeight: "110px" }}
              />
              <p className="lead text-center">Our World</p>
              <p className="text-center">We Are In 2 Countries</p>
            </Col>
            <Col sm={12} md={4} className="text-center">
              <img
                className="img-fluid"
                src="https://cannon-studio.s3.amazonaws.com/globalconnexx/about/2.png"
                alt=""
                style={{ maxHeight: "110px" }}
              />
              <p className="lead text-center">Our Promise</p>
              <p className="text-center">
                <strong>100%</strong> Of All Money Goes To Missions
              </p>
            </Col>
            <Col sm={12} md={4} className="text-center">
              <img
                className="img-fluid"
                src="https://cannon-studio.s3.amazonaws.com/globalconnexx/about/3.png"
                alt=""
                style={{ maxHeight: "110px" }}
              />
              <p className="lead text-center">Our Reach</p>
              <p className="text-center">10,000+ Lives Touched</p>
            </Col>
          </Row>
        </Col>
      </Row>
      <div id="about" />
      <Row className="mx-0">
        <Col
          sm={12}
          md={{ size: 10, offset: 1 }}
          style={{ paddingTop: "48px", paddingBottom: "48px" }}
        >
          <Row>
            <Col sm={12} md={6}>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src="https://player.vimeo.com/video/256277669?autoplay=0&amp;autopause=0"
                  allowFullScreen={1}
                ></iframe>
              </div>
              <br />
              <br />
            </Col>
            <Col sm={12} md={6}>
              <h3>About Us</h3>
              <hr />
              <p>
                Global Connexx is a 501c3 organization that exists to serve{" "}
                <strong>ONE GOD</strong>, that commands us to share{" "}
                <strong>ONE MESSAGE</strong>, to <strong>ALL NATIONS</strong>.
                GC partners with multiple mission and church organizations both
                locally and abroad to share the gospel message through
                humanitarian efforts, orphan sponsorships, medical clinics, and
                evangelistic outreach opportunities. GC also exists to serve as
                a hub for other mission organizations to connect to volunteers
                and financial resources in hopes of furthering their
                organizational footprint.
              </p>
              <p>
                GC is currently partnered with 3 ministries in the Philippines
                and 1 ministry in India. GC actively sends funding to these
                ministries in an effort to further the outreach efforts of local
                ministry leaders in providing food, school supplies, Bible
                distribution, gospel outreach materials, and new church and
                orphanage construction supplies.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>

      <div id="covid19" />
      <Row className="mx-0">
        <Col
          sm={12}
          md={{ size: 10, offset: 1 }}
          style={{ padding: "48px 15px" }}
        >
          <Row>
            <Col>
              <h3>COVID-19 Response</h3>
              <hr />
              <p className="lead">
                Global Connexx sent donations to Pastor Medabalimi Paul in
                Anchorage Pradeshi, South India to provide desperately needed
                food to underserved communities during the COVID-19 pandemic.
              </p>

              <ImageGrid
                images={[
                  {
                    title: "",
                    url: "https://cannon-studio.s3.amazonaws.com/globalconnexx/covid19/IMG_1810.JPG",
                  },
                  {
                    title: "",
                    url: "https://cannon-studio.s3.amazonaws.com/globalconnexx/covid19/IMG_1811.JPG",
                  },
                  {
                    title: "",
                    url: "https://cannon-studio.s3.amazonaws.com/globalconnexx/covid19/IMG_1813.JPG",
                  },
                  {
                    title: "",
                    url: "https://cannon-studio.s3.amazonaws.com/globalconnexx/covid19/IMG_1814.JPG",
                  },
                  {
                    title: "",
                    url: "https://cannon-studio.s3.amazonaws.com/globalconnexx/covid19/IMG_1817.JPG",
                  },
                  {
                    title: "",
                    url: "https://cannon-studio.s3.amazonaws.com/globalconnexx/covid19/image0.jpg",
                  },
                  {
                    title: "",
                    url: "https://cannon-studio.s3.amazonaws.com/globalconnexx/covid19/image2.jpg",
                  },
                  {
                    title: "",
                    url: "https://cannon-studio.s3.amazonaws.com/globalconnexx/covid19/image3.jpg",
                  },
                ]}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <div id="donations" />
      <Row className="mx-0 blue-section">
        <Col sm={12} md={{ size: 10, offset: 1 }}>
          <Row>
            <Col sm={12} md={8}>
              <h2>
                Donations
                <br />
                <small>Help Share the Gospel to all Nations</small>
              </h2>
              <hr />
              <p>
                Your generous giving is what allows Global Connexx the financial
                foundation to share the gospel message to thousands of unreached
                people groups both locally and abroad. We invite you to support
                the ongoing missional and humanitarian efforts of Global Connexx
                by giving a one-time donation or committing to a monthly
                donation. We are forever grateful for your generosity and stand
                by our promise that{" "}
                <strong>100% of all donated money goes to missions</strong>!
              </p>
            </Col>
            <Col sm={12} md={4}>
              <a
                href="https://secure.subsplash.com/ui/access/RT8QMH/ute_8wUppSvk70fcpDurCFsQDax-fBGqRg7r_qks1uQXddUnbg7ctr4yK40VE37KNNEmCkqDW0MRtXp6JB3EmPf_pbiaw8BTlAAQDFgM_9ylBHzflKXUUNvflKwQN9Kft8GfVHPF6Hi-X34RLqtLBtQ"
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-lg btn-primary"
                style={{ padding: "18px 36px" }}
              >
                Make a Donation
              </a>
            </Col>
          </Row>
        </Col>
      </Row>

      <div id="causes" />
      <Row className="mx-0">
        <Col
          sm={12}
          md={{ size: 10, offset: 1 }}
          style={{ padding: "48px 15px" }}
        >
          <h3>Our Causes</h3>
          <hr />

          <div className="card-hz mb-5">
            <Row>
              <Col sm={12} md={3}>
                <img
                  src="https://cannon-studio.s3.amazonaws.com/globalconnexx/projects/philippines.jpg"
                  alt="Philippines Outreach"
                  title="Philippines Outreach"
                  className="img-fluid"
                />
              </Col>
              <Col>
                <div className="card-hz-body">
                  <h3>Philippines Outreach</h3>
                  <hr />
                  <h5>
                    Tim Tapang - San Carlos, Negros{" "}
                    <small>// Seaside Berean Gospel Church</small>
                  </h5>
                  <p>
                    Pastor Tim leads a local church, Seaside Berean Gospel
                    Church in San Carlos, Negros and is also an active church
                    planter throughout the island. Pastor Tim also founded a
                    local prison ministry that has reached many incarcerated men
                    and women.
                  </p>

                  <hr />

                  <h5>
                    Pastor Rey Manolo{" "}
                    <small>// Agape Baptist Bible Church</small>
                  </h5>
                  <p>
                    Pastor Rey leads a local church, Agape Baptist Bible Church,
                    To-oy, Himamaylan City and is active in mentoring local
                    pastors and hosting pastor training conferences. Pastor Rey
                    has a heart for children and leads many school conferences
                    sharing the gospel message.
                  </p>

                  <hr />

                  <h5>
                    Pastor Joannis Tangub{" "}
                    <small>// Bible Believing Grace Baptist Temple</small>
                  </h5>
                  <p>
                    Pastor Joannis is an IBMA (Independent Baptist Mission for
                    Asians) Missionary Representative and avid church planter in
                    the Philippines, Japan, and South Korea. Pastor Joannis
                    currently pastors Bible Believing Grace Baptist Temple,
                    Danao, Cebu and also founded a local Bible College, Chapel
                    Hill Grace Baptist Seminary, with the sole purpose of
                    training and equipping individuals to serve as missionaries
                    and pastors of remote village churches.
                  </p>
                </div>
              </Col>
            </Row>
          </div>

          <div className="card-hz mb-5">
            <Row>
              <Col sm={12} md={3}>
                <img
                  src="https://cannon-studio.s3.amazonaws.com/globalconnexx/projects/india.jpg"
                  alt="India Outreach"
                  title="India Outreach"
                  className="img-fluid"
                />
              </Col>
              <Col>
                <div className="card-hz-body">
                  <h3>India Outreach</h3>
                  <hr />

                  <h5>
                    Pastor Babu Paul <small>// Open the Door Ministry</small>
                  </h5>
                  <p>
                    <a
                      href="https://www.openthedoorministryindia.weebly.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      www.openthedoorministryindia.weebly.com
                    </a>
                  </p>
                  <p>
                    Pastor Babu founded Open the Door Ministry which provides
                    food and shelter to underprivileged children, in addition
                    to, leading crusades and evangelistic conferences. Pastor
                    Babu also founded a widow and orphan ministry which provides
                    food and shelter to impoverished widows and orphans.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <div id="gallery" />
      <ImageGrid
        images={[
          {
            title: "",
            url: "https://cannon-studio.s3.amazonaws.com/globalconnexx/gallery/IMG_0319.jpg",
          },
          {
            title: "",
            url: "https://cannon-studio.s3.amazonaws.com/globalconnexx/gallery/IMG_0364.jpg",
          },
          {
            title: "",
            url: "https://cannon-studio.s3.amazonaws.com/globalconnexx/gallery/IMG_0380.jpg",
          },
          {
            title: "",
            url: "https://cannon-studio.s3.amazonaws.com/globalconnexx/gallery/IMG_0467.jpg",
          },
          {
            title: "",
            url: "https://cannon-studio.s3.amazonaws.com/globalconnexx/gallery/IMG_0497.jpg",
          },
          {
            title: "",
            url: "https://cannon-studio.s3.amazonaws.com/globalconnexx/gallery/IMG_0525.jpg",
          },
          {
            title: "",
            url: "https://cannon-studio.s3.amazonaws.com/globalconnexx/gallery/IMG_0745.jpg",
          },
          {
            title: "",
            url: "https://cannon-studio.s3.amazonaws.com/globalconnexx/gallery/IMG_0746.jpg",
          },
          {
            title: "",
            url: "https://cannon-studio.s3.amazonaws.com/globalconnexx/gallery/IMG_0747.jpg",
          },
          {
            title: "",
            url: "https://cannon-studio.s3.amazonaws.com/globalconnexx/gallery/IMG_0749.jpg",
          },
          {
            title: "",
            url: "https://cannon-studio.s3.amazonaws.com/globalconnexx/gallery/IMG_2575.jpg",
          },
          {
            title: "",
            url: "https://cannon-studio.s3.amazonaws.com/globalconnexx/gallery/IMG_2600.jpg",
          },
          {
            title: "",
            url: "https://cannon-studio.s3.amazonaws.com/globalconnexx/gallery/IMG_2611.jpg",
          },
          {
            title: "",
            url: "https://cannon-studio.s3.amazonaws.com/globalconnexx/gallery/IMG_2717.jpg",
          },
          {
            title: "",
            url: "https://cannon-studio.s3.amazonaws.com/globalconnexx/gallery/IMG_2781.jpg",
          },
          {
            title: "",
            url: "https://cannon-studio.s3.amazonaws.com/globalconnexx/gallery/IMG_2861.jpg",
          },
          {
            title: "",
            url: "https://cannon-studio.s3.amazonaws.com/globalconnexx/gallery/IMG_3221.jpg",
          },
          {
            title: "",
            url: "https://cannon-studio.s3.amazonaws.com/globalconnexx/gallery/IMG_4969.jpg",
          },
          {
            title: "",
            url: "https://cannon-studio.s3.amazonaws.com/globalconnexx/gallery/IMG_4980.jpg",
          },
          {
            title: "",
            url: "https://cannon-studio.s3.amazonaws.com/globalconnexx/gallery/IMG_4984.jpg",
          },
        ]}
      />

      <div id="leadership" />
      <Row className="mx-0">
        <Col sm={12} md={{ size: 10, offset: 1 }} style={{ padding: "48px 0" }}>
          <h3>Leadership</h3>
          <hr />
          <p>
            Global Connexx operates under the leadership and accountability of a
            Board of Directors. This team is responsible for providing oversight
            to all ministry, financial, and operational aspects in accordance
            with the <strong>Word of God</strong> and organizational bylaws.
          </p>
          <Row>
            <Col sm={12} md={8}>
              <br />
              <br />
              <Leadership
                leaders={[
                  { name: "Danny Smith", title: "Co-Founder & President" },
                  {
                    name: "Chris Walker",
                    title: "Co-Founder & Board Member",
                  },
                  {
                    name: "Carla Walker",
                    title: "Co-Founder & Board Member",
                  },
                  { name: "Terry Ledbetter", title: "Board Member" },
                  { name: "David Leatherman", title: "Board Member" },
                  { name: "Randy McCoy, Ed.D.", title: "Board Member" },
                  { name: "Gary Townsend", title: "Board Member" },
                ]}
              />
            </Col>
            <Col sm={12} md={4}>
              <div className="become-volunteer">
                <img
                  className="img-fluid"
                  src="https://cannon-studio.s3.amazonaws.com/globalconnexx/logo-icon.png"
                  style={{ maxHeight: "85px" }}
                  alt=""
                />
                <h2>
                  <small>Become a</small>
                  <br />
                  Volunteer
                </h2>
                <p>
                  We are always looking to connect with people passionate about
                  spreading the love of God to unreached people groups. There
                  are many ways to get connected to Global Connexx, contact us
                  now to find out more information!
                </p>
                <a href="#contact" className="btn btn-light">
                  Contact Us Now
                </a>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <div id="contact" />
      <div className="footer-container">
        <Row className="mx-0">
          <Col sm={12} md={{ size: 10, offset: 1 }} className="px-0">
            <h3>Contact Us</h3>
            <hr />
            <p className="lead">
              We would love to chat with you! Feel free to drop us a line, send
              us an email, or mail us a letter. However you choose to
              communicate we are here and want to hear from you.
            </p>
            <Row>
              <Col sm={12} md={6} className="footer-info">
                <FontAwesomeIcon icon={faMapPin} />
                <p>
                  1020 N Gloster St #133
                  <br />
                  Tupelo, MS 38804-1202
                </p>
              </Col>
              <Col sm={12} md={6} className="footer-info">
                <FontAwesomeIcon icon={faEnvelope} />
                <p>
                  <a href="mailto:info@globalconnexx.com">
                    info@globalconnexx.com
                  </a>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mx-0">
          <Col
            sm={12}
            md={{ size: 10, offset: 1 }}
            className="px-0"
            style={{ paddingTop: "48px" }}
          >
            <p className="text-mute">Powered by Cannon Studio</p>
          </Col>
        </Row>
      </div>
    </Container>
  );
};
