import React, { useState } from "react";
import "./Carousel.css";

export default (props) => {
  const [curIndex, setCurIndex] = useState(0);

  const slides = props.slides.map((slide, i) => {
    const styles = { backgroundImage: `url("${slide.src}")` };
    const active = curIndex === i ? "active" : "";
    return (
      <div key={i} className={`gc-carousel-item ${active}`} style={styles} />
    );
  });

  return <div className="gc-carousel">{slides}</div>;
};
