import React, { useState } from "react";
import { Row, Col, Button } from "reactstrap";

import "./ImageGrid.css";

export default (props) => {
  const [curIndex, setCurIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = (index) => {
    console.log("Triggered");
    setCurIndex(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const nextSlide = () => {
    let i = curIndex + 1;

    if (i >= props.images.length) {
      i = 0;
    }

    setCurIndex(i);
  };

  const prevSlide = () => {
    let i = curIndex - 1;

    if (i < 0) {
      i = props.images.length - 1;
    }

    setCurIndex(i);
  };

  const images = props.images.map((image, i) => {
    return (
      <Col
        key={i}
        sm={4}
        md={3}
        className="px-0 mx-0"
        style={{
          backgroundImage: `url("${image.url}")`,
          backgroundRepeat: "norepeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "180px",
        }}
        onClick={(e) => {
          openModal(i);
        }}
      />
    );
  });

  return (
    <React.Fragment>
      <Row className="px-0 mx-0">{images}</Row>
      <div className="modal" style={{display: modalOpen ? 'block' : 'none'}}>
        <span className="close cursor" onClick={(e) => closeModal()}>
          &times;
        </span>
        <div className="modal-content">
          <div className="modal-slide">
            <img
              src={props.images[curIndex].url}
              className="img-fluid"
              alt=""
            />
          </div>
          
          <Button type="button" className="prev" onClick={e => prevSlide()}>&#10094;</Button>
          <Button type="button" className="next" onClick={e => nextSlide()}>&#10095;</Button>

        </div>
      </div>
    </React.Fragment>
  );
};
