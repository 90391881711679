import React from 'react';
import { Row, Col } from 'reactstrap';

export default ({leaders}) => {
  const list = leaders.map((leader, i) => {
    return (
      <Col key={i} sm={12} md={4}>
        <h5 className="mb-5">{leader.name}<br /><small>{leader.title}</small></h5>
      </Col>
    );
  });

  return (
    <Row>
      {list}
    </Row>
  )
};